import axios from 'axios'

export default {
  pricingSchedules(groupId) {
    return axios
      .get(
        `/api/super_admin/client_pricing_schedules/pricing_schedules?id=${groupId}`,
        {
          handleErrors: false
        }
      )
      .then(res => {
        return res.data
      })
      .catch(function() {
        return null
      })
  },
  generateToken() {
    return axios
      .get('/api/super_admin/client_pricing_schedules/generate_token', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
